import React, { useState } from 'react'
import PieChart from 'react-minimal-pie-chart'
import { Typography, Row, Button, Col, Divider, Avatar, Table } from 'antd'
import { colorSet, carrotLight, carrot } from '../../Helpers/const'
const { Text, Title } = Typography

const avatarStyle = {
  color: carrot,
  backgroundColor: carrotLight[3],
  height: 25,
  width: 25,
  lineHeight: '25px',
  fontSize: 14,
  marginRight: 10
}

const Venue = ({ venue }) => {
  const [expanded, setExpanded] = useState()
  if (venue) {
    const venueName = venue[Object.keys(venue)[0]].venue_name.replace('&amp;', '&')
    let numOfRecords = 0
    Object.keys(venue).forEach(week => (numOfRecords += venue[week].total_rows))
    const numOfWeeks = Object.keys(venue).length

    const tableData = Object.keys(venue).map((record, idx) => ({
      id: idx,
      date: record,
      ...venue[record]
    }))

    const columns = [
      {
        title: <Text strong>Date range</Text>,
        dataIndex: 'date',
        key: 'date',
        render: date => (
          <>
            <Avatar style={avatarStyle} icon='calendar' />
            <Text>{date}</Text>
          </>
        )
      },
      {
        title: <Text strong>Total rows</Text>,
        dataIndex: 'total_rows',
        key: 'total_rows'
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: id => (
          <Button type='link' onClick={() => (expanded === id ? setExpanded() : setExpanded(id))}>
            Details
          </Button>
        )
      }
    ]

    const chartData = record => {
      const skip = ['id', 'date', 'total_rows', 'venue_name']
      const pie_slices = Object.keys(record).filter(key => !skip.includes(key))
      return pie_slices.map((slice, idx) => ({
        title: slice,
        value: record[slice].rows,
        color: `${colorSet[idx]}e4`
      }))
    }

    return (
      <>
        <Title level={4}>{venueName}: Summary</Title>
        <Text type='secondary' style={{ fontSize: 16, fontWeight: 600 }}>
          {numOfRecords} records over {numOfWeeks} weeks
        </Text>
        <Divider />
        <Table
          columns={columns}
          rowKey={'id'}
          rowClassName={'users'}
          dataSource={tableData}
          style={{ marginLeft: 10, maxWidth: 800 }}
          pagination={{
            simple: true,
            defaultPageSize: 10,
            hideOnSinglePage: true
          }}
          expandedRowKeys={[expanded]}
          expandedRowRender={record => (
            <Row type='flex' style={{ maxHeight: 250 }}>
              <Col span={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Row style={{ width: '100%', height: 'fit-content', paddingLeft: 70 }}>
                  <Text strong>Distribution</Text>
                </Row>
                <PieChart
                  label={({ data, dataIndex }) => {
                    const point = data[dataIndex]
                    return `${point.title.replace('Miscellaneous', 'Misc.')} (${Math.round(
                      point.percentage
                    )}%)`
                  }}
                  labelPosition={145}
                  labelStyle={{ fontSize: '4px' }}
                  radius={16}
                  viewBoxSize={[90, 80]}
                  data={chartData(record)}
                  cx={50}
                  cy={40}
                />
              </Col>
              <Col span={10}>
                <Row style={{ marginBottom: 25 }}>
                  <Col span={4}>
                    <Text strong>Category</Text>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <Text strong>Records</Text>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <Text strong>Avg. variance</Text>
                  </Col>
                </Row>
                {chartData(record).map(({ color, title, value }) => (
                  <Row key={title}>
                    <Col span={4}>
                      <Text style={{ color }} strong>
                        {title.replace('Miscellaneous', 'Misc.')}
                      </Text>
                    </Col>
                    <Col span={10} style={{ textAlign: 'right' }}>
                      <Text>{value}</Text>
                    </Col>
                    <Col span={10} style={{ textAlign: 'right' }}>
                      <Text>{`${record[title].avg_variance} %`}</Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          )}
        />
      </>
    )
  } else return <div />
}
export default Venue
