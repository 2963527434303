import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, List, Button, Typography, Input } from 'antd'
import { carrot, PAGES, USERS_URL } from '../../Helpers/const'
import { useAuthValue } from '../../Auth/AuthContext'
import ChangePw from './ChangePw'
import { call } from '../../Helpers/Call'
const { Text } = Typography
const { Item } = List
const { Meta } = Item

const capitalize = word => word[0].toUpperCase() + word.substring(1)

const userText = 'Missing a venue? Contact your manager for help.'
const adminText = (
  <>
    You can edit all venues on the
    <Link to={PAGES.ADMIN.route}> Admin page.</Link>
  </>
)

const Account = () => {
  const { user, setUser, token } = useAuthValue()
  const [editing, setEditing] = useState(false)
  const [field, setField] = useState()
  const [newValue, setNewValue] = useState()

  useEffect(() => {
    if (!editing) {
      setField()
      setNewValue()
    }
  }, [editing])

  const saveChange = formValues => {
    const onSuccess = async data => {
      setField()
      setNewValue()
      setEditing(false)
      await setUser(data)
      localStorage.setItem('profile', JSON.stringify(data))
    }
    const url = `${USERS_URL}/${user.id}`
    call(url, 'PUT', token, onSuccess, formValues)
  }

  const editBtn = (field, value) => (
    <Button
      type='link'
      icon='edit'
      onClick={() => {
        setEditing(!editing)
        setField(field)
        setNewValue(value)
      }}
    />
  )

  const saveBtn = (
    <Button
      type='primary'
      size='small'
      style={{ margin: '10px 0px' }}
      htmlType='submit'
      onClick={() => {
        if (field && newValue) saveChange({ [`${field.toLowerCase()}`]: newValue })
      }}>
      Save
    </Button>
  )

  const editField = (
    <>
      <Input value={newValue} onChange={e => setNewValue(e.target.value)} />
      {saveBtn}
    </>
  )

  const data = [
    {
      title: 'Name',
      description: capitalize(user.name),
      actions: [editBtn('Name', capitalize(user.name))],
      edit: editField,
    },
    {
      title: 'Email',
      description: user.email,
      actions: [editBtn('Email', user.email)],
      edit: editField,
    },
    {
      title: 'Password',
      description: <span style={{ WebkitTextSecurity: 'disc' }}>**********</span>,
      actions: [editBtn('Password')],
      edit: <ChangePw saveChange={saveChange} />,
    },
    {
      title: 'Your Venues',
      description: (
        <>
          {user.venues.map(item => (
            <span key={item.name}>
              {item.name.replace('&amp;', '&')}
              <br />
            </span>
          ))}
          <br />
          <Text style={{ color: carrot, fontSize: 12 }} strong>
            {user.role === 'admin' ? adminText : userText}
          </Text>
        </>
      ),
    },
  ]
  return (
    <>
      <Card style={{ width: 450, minHeight: 300, margin: 'auto' }} bordered={false}>
        <List itemLayout='horizontal'>
          {data.map((item, i) => (
            <Item actions={item.actions} key={i}>
              <Meta
                title={<Text strong>{item.title}</Text>}
                description={field === item.title ? item.edit : item.description}
              />
            </Item>
          ))}
        </List>
      </Card>
    </>
  )
}

export default Account
