import React, { useState } from 'react'
import { Layout, Icon, Menu, Typography, Row } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useAuthValue } from '../Auth/AuthContext'
import { ReactComponent as Logo } from '../Styles/beer.svg'
import { carrotLight, PAGES } from '../Helpers/const'

const { Item } = Menu
const { Sider } = Layout
const { Title } = Typography

const rowStyle = {
  borderRight: `1px solid ${carrotLight[5]}`,
  padding: '20px 20px 2px 0px',
  textAlign: 'center'
}

const logoStyle = { width: 30, marginRight: 10 }

const SideNav = () => {
  const location = useLocation()
  const path = location.pathname
  const { logOut, user } = useAuthValue()
  const [collapsed, setCollapsed] = useState(false)

  const userLinks = () => {
    let availablePages = [PAGES.CREATE, PAGES.REPORTS, PAGES.ACCOUNT]
    if (user && user.role === 'admin') availablePages.push(PAGES.ADMIN)
    return availablePages.map(page => (
      <Item key={page.route}>
        <Link to={page.route}>{page.nav}</Link>
      </Item>
    ))
  }

  const activePage = path === '/login' || path === '/' ? '/create' : path

  return (
    <Sider
      trigger={collapsed ? true : null}
      collapsible
      inlinecollapsed={collapsed ? 'true' : null}
      collapsedWidth={0}
      theme='light'
      breakpoint='sm'
      onBreakpoint={setCollapsed}
      width={150}
      style={{ minHeight: '100%' }}>
      <Row style={rowStyle}>
        <Title level={4} style={{ letterSpacing: 1, color: 'rgba(0,0,0,0.85' }}>
          <Icon style={logoStyle} component={() => <Logo />} />
          NLG
        </Title>
      </Row>
      <Menu
        selectedKeys={[activePage]}
        mode='inline'
        style={{
          minHeight: '100%',
          borderRight: `1px solid ${carrotLight[5]}`
        }}>
        {userLinks()}
        <Menu.Divider style={{ marginTop: -8 }} />
        <Item key='signout' onClick={() => logOut()}>
          Sign Out
        </Item>
      </Menu>
    </Sider>
  )
}

export default SideNav
