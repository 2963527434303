import React from "react";
import { renderUnicode } from "./ContainersRecountTable";

export default function FinishedMessage() {
  return (
    <div
      id="background"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          padding: "20px",
          width: "90%",
          backgroundColor: "hsl(168, 40%, 55%)",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <h1 style={{ margin: 0 }}>Finished</h1>
        <p style={{ fontSize: "5em", margin: 0 }}>{renderUnicode("2705")}</p>
        <p>Thank you for submitting your recounts.</p>
      </div>
    </div>
  );
}
