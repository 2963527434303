import React, { createContext, useContext, useEffect, useState } from 'react'
import { PAGES } from '../Helpers/const'

export const AuthContext = createContext()
export const useAuthValue = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState()
  const [token, setToken] = useState()

  useEffect(() => {
    const checkCurrent = async () => {
      const expiryTime = JSON.parse(localStorage.getItem('expires_at'))
      const authToken = localStorage.getItem('auth_token')
      const userProfile = localStorage.getItem('profile')

      const expired = expiryTime === null || new Date().getTime() / 1000 > expiryTime
      const hasAuthToken = authToken && authToken.toString() !== 'undefined'
      const hasProfile = userProfile && userProfile.toString() !== 'undefined'

      if (hasAuthToken && hasProfile) {
        if (!expired) {
          setUser(JSON.parse(userProfile))
          setToken(authToken)
          return true
        }
      } else return false
    }

    const initAuth = async () => {
      setLoading(true)
      setLoggedIn(await checkCurrent())
      setLoading(false)
    }
    initAuth()
  }, [])

  const login = result => {
    const { expiresIn, user, authToken } = result

    localStorage.setItem('auth_token', authToken)
    localStorage.setItem('expires_at', JSON.stringify(expiresIn))
    localStorage.setItem('profile', JSON.stringify(user))

    setLoggedIn(true)
    setUser(user)
    setToken(authToken)
    setLoading(false)
    window.location.replace(PAGES.CREATE.route)
  }

  const logOut = () => {
    ;['auth_token', 'expires_at', 'profile'].forEach(item => localStorage.removeItem(item))
    setLoggedIn(false)
    setUser()
    window.location.replace(PAGES.LOGIN.route)
  }

  return (
    <AuthContext.Provider
      value={{ login, logOut, loading, setLoading, loggedIn, user, token, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}
