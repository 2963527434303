import * as React from "react";
import Icon from "@ant-design/icons";
import image from "./green-up-right-arrow.png";

export const UpArrowIcon = ({ pixelSize }) => {
  return (
    <Icon
      component={() => (
        <img
          alt="Up right arrow"
          width={pixelSize}
          height={pixelSize}
          src={image}
        />
      )}
    />
  );
};
