import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Modal, Form, Typography, Button } from "antd";
import { topaz, VENUES_URL, PAGES } from "../../Helpers/const";
import { useAuthValue } from "../../Auth/AuthContext";
import { call } from "../../Helpers/Call";
const { Text } = Typography;

const numText = {
  display: "block",
  textAlign: "center",
  fontSize: 12,
  lineHeight: 3,
};

const VenueAdmin = ({ venueList, setVenueList }) => {
  const { token } = useAuthValue();
  const EditableContext = React.createContext();

  const EditableCell = (props) => {
    const renderCell = ({ getFieldDecorator }) => {
      const {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      } = props;
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `${title} is required.`,
                  },
                ],
                initialValue: record[dataIndex].replace("&amp;", "&"),
              })(<Input style={{ width: title === "Name" ? "100%" : 100 }} />)}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    };

    return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>;
  };

  const EditableTable = ({ form }) => {
    const [editingKey, setEditingKey] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [toDelete, setToDelete] = useState({});

    const rawColumns = [
      {
        title: "Name",
        dataIndex: "name",
        editable: true,
        defaultSortOrder: "ascend",
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
        render: (name, record) =>
          name && (
            <Button type="link">
              <Link to={`${PAGES.VENUE.route}?id=${record.id}`}>
                {name.replace("&amp;", "&")}
              </Link>
            </Button>
          ),
      },
      {
        title: "Client ID",
        dataIndex: "client_id",
        render: (client_id) => <Text style={numText}>{client_id}</Text>,
        editable: true,
        align: "center",
      },
      {
        title: "Access Code",
        dataIndex: "access_code",
        render: (access_code) => <Text style={numText}>{access_code}</Text>,
        editable: true,
        align: "center",
      },
      {
        title: "Edit",
        dataIndex: "operation",
        align: "center",
        width: 150,
        render: (text, record) => {
          const editable = record.id === editingKey;
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    onClick={() => updateVenue(form, record.id)}
                    type="primary"
                    size="small"
                    style={{ marginRight: 5 }}
                  >
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Button
                onClick={() => setEditingKey("")}
                size="small"
                icon="close"
              />
            </span>
          ) : (
            <Button
              icon="edit"
              size="small"
              type="link"
              disabled={editingKey !== ""}
              onClick={() => setEditingKey(record.id)}
            />
          );
        },
      },
      {
        title: "Delete",
        dataIndex: "delete",
        render: (id, record) => (
          <Button
            icon="delete"
            size="small"
            type="link"
            onClick={() => {
              setModalVisible(true);
              setToDelete({
                ...record,
                name: record.name.replace("&amp;", "&"),
              });
            }}
            style={{ color: topaz }}
          />
        ),
        align: "center",
      },
    ];

    const url = (id) => `${VENUES_URL}/${id}`;
    const onSuccess = (data) => {
      setVenueList(data);
      setEditingKey("");
    };

    const updateVenue = async (form, id) => {
      form.validateFields(async (error, row) => {
        if (error) return;
        else call(url(id), "PUT", token, onSuccess, row);
      });
    };

    const deleteVenue = async (id) => {
      call(url(id), "DELETE", token, onSuccess);
    };

    const columns = rawColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: record.id === editingKey,
        }),
      };
    });

    const deleteConfirm = (
      <Modal
        title={<Text style={{ color: "black" }}>Delete {toDelete.name}?</Text>}
        visible={modalVisible}
        onOk={() => deleteVenue(toDelete.id)}
        onCancel={() => setModalVisible(false)}
      >
        Are you sure you want to permanently erase {toDelete.name}? All users
        will lose their past reports and the ability to generate new reports for
        this venue.
      </Modal>
    );

    return (
      <EditableContext.Provider value={form}>
        {modalVisible && deleteConfirm}
        <Table
          components={{ body: { cell: EditableCell } }}
          dataSource={venueList}
          columns={columns}
          rowClassName="editable-row"
          rowKey={"id"}
          style={{ maxWidth: 850, margin: "auto" }}
          pagination={false}
        />
      </EditableContext.Provider>
    );
  };

  const EditableFormTable = Form.create()(EditableTable);

  return <EditableFormTable />;
};

export default VenueAdmin;
