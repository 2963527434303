import React from 'react'
import { Form, Input, InputNumber, Modal } from 'antd4'
import { BASE_URL } from '../../Helpers/const'
import Checkbox from 'antd4/lib/checkbox/Checkbox'

export default function EditModal({ isEditModalVisible, hideEditModal, schedule, updateDetails, handleFailureAlert, handleSuccessAlert }) {
  const [frequencyInWeeks, setFrequencyInWeeks] = React.useState(schedule.frequency_in_weeks)
  const [timeOfDay, setTimeOfDay] = React.useState(schedule.time_of_day)
  const [monday, setMonday] = React.useState(schedule.monday)
  const [tuesday, setTuesday] = React.useState(schedule.tuesday)
  const [wednesday, setWednesday] = React.useState(schedule.wednesday)
  const [thursday, setThursday] = React.useState(schedule.thursday)
  const [friday, setFriday] = React.useState(schedule.friday)
  const [saturday, setSaturday] = React.useState(schedule.saturday)
  const [sunday, setSunday] = React.useState(schedule.sunday)
  
  const handleEdit = (e) => {
    e.preventDefault()
    fetch(BASE_URL + `/audit_schedules/${schedule.id}`,
      { 
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`
        },
        body: JSON.stringify({
          audit_schedule: {
            frequency_in_weeks: frequencyInWeeks,
            time_of_day: timeOfDay,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
          }
        })
      }
    )
    .then((res) => res.json()) 
    .then((res) => {
      if(res.error) {
        hideEditModal()
        handleFailureAlert()
      } else {
        updateDetails(res.audit_schedule)
        hideEditModal()
        handleSuccessAlert()
      }
    })
  }
  
  return (
    <Modal visible={isEditModalVisible} onCancel={hideEditModal} okText={"Update"} onOk={handleEdit}>
      <Form>
        <Form.Item label="Frequency (weeks)">
          <InputNumber
            value={frequencyInWeeks}
            onChange={(newFrequencyInWeeks) =>
              setFrequencyInWeeks(newFrequencyInWeeks)
            }
          />
        </Form.Item>
        <Form.Item label="Time of day">
          <Input
            placeholder="e.g. 17:00"
            value={timeOfDay}
            onChange={(e) => setTimeOfDay(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Weekdays">
          <Checkbox checked={monday} onChange={() => setMonday(!monday)}>
            Monday
          </Checkbox>
          <br />
          <Checkbox checked={tuesday} onChange={() => setTuesday(!tuesday)}>
            Tuesday
          </Checkbox>
          <br />
          <Checkbox checked={wednesday} onChange={() => setWednesday(!wednesday)}>
            Wednesday
          </Checkbox>
          <br />
          <Checkbox checked={thursday} onChange={() => setThursday(!thursday)}>
            Thursday
          </Checkbox>
          <br />
          <Checkbox checked={friday} onChange={() => setFriday(!friday)}>
            Friday
          </Checkbox>
          <br />
          <Checkbox checked={saturday} onChange={() => setSaturday(!saturday)}>
            Saturday
          </Checkbox>
          <br />
          <Checkbox checked={sunday} onChange={() => setSunday(!sunday)}>
            Sunday
          </Checkbox>
          <br />
        </Form.Item>
      </Form>
    </Modal>
  )
}
