import React from "react";
import { Spin } from "antd4";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";

import { BASE_URL } from "../../Helpers/const";

import "./AuditReport.css";
import AlcoholBrandCard from "./AlcoholBrandCard";
import ConfirmationModal from "./ConfirmationModal";
import FinishedMessage from "./FinishedMessage";

Modal.setAppElement("#root");

const AuditReport = ({ auditReportId }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFinished, setIsFinished] = React.useState(false);
  const [auditReport, setAuditReport] = React.useState(null);
  const [currentBrandIndex, setCurrentBrandIndex] = React.useState(0);
  const [containerRecounts, setContainerRecounts] = React.useState(null);
  const history = useHistory();
  const { search } = useLocation();
  const secret = new URLSearchParams(search).get("scrt");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [recountDifferences, setRecountDifferences] = React.useState(null);

  const submitRecounts = () => {
    return fetch(
      BASE_URL +
        `/recount/${auditReport.id}/brands/${auditReport.alcohols[currentBrandIndex].id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ containers: containerRecounts }),
      }
    ).then(() => {
      closeModal()
      cycleToNextBrand();
      // setIsLoading(false);
    });
  };

  React.useEffect(() => {
    if (containerRecounts) {
      setIsLoading(false);
    }
  }, [containerRecounts]);

  React.useEffect(
    function () {
      if (recountDifferences !== null) {
        if (recountDifferences.length === 0) {
          setIsLoading(true);
          submitRecounts();
        } else {
          openModal();
        }
      }
    },
    [recountDifferences] // eslint-disable-line
  );

  const setStateForCurrentBrand = () => {
    const nextBrand = auditReport.alcohols[currentBrandIndex];
    const newContainerRecounts = nextBrand.containers.reduce(
      (allContainers, container) => {
        const newOpenBottles = container.open_bottles.reduce(
          (allOpenBottles, bottle) => {
            allOpenBottles[bottle.id] = { reweight: bottle.reweight };
            return allOpenBottles;
          },
          {}
        );

        allContainers[container.id] = {
          full_recount: container.full_recount,
          open_bottles: newOpenBottles,
        };
        return allContainers;
      },
      {}
    );
    setContainerRecounts(newContainerRecounts);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    // setAuditReport(MOCK_AUDIT_REPORT);

    fetch(BASE_URL + `/audit_reports/${auditReportId}?scrt=${secret}`)
      .then((r) => r.json())
      .then((auditReport) => {
        setAuditReport(auditReport);
      })
      .catch(() => history.push("/"));
  }, [auditReportId, history, secret]);

  React.useEffect(() => {
    if (auditReport) setStateForCurrentBrand();
  }, [auditReport, currentBrandIndex]); // eslint-disable-line

  if (isLoading)
    return (
      <div style={{ textAlign: "center" }}>
        <Spin size="large" />
      </div>
    );

  if (!auditReport) history.push("/");

  const handleChange = ({ target }, containerId, bottleType, bottleId) => {
    const newWeight = target.value ? parseFloat(target.value) : "";

    if (isNaN(newWeight)) return;

    if (bottleType === "full") {
      setContainerRecounts({
        ...containerRecounts,
        [containerId]: {
          ...containerRecounts[containerId],
          full_recount: newWeight,
        },
      });
    } else {
      setContainerRecounts({
        ...containerRecounts,
        [containerId]: {
          ...containerRecounts[containerId],
          open_bottles: {
            ...containerRecounts[containerId].open_bottles,
            [bottleId]: newWeight,
          },
        },
      });
    }
  };

  const handleSubmit = () => {
    // If there are no required recounts, move to the next brand
    // if (Object.keys(containerRecounts).length === 0) {
    //   cycleToNextBrand();
    // }
    // Are all required recounts entered?
    const everyContainerHasARecount = Object.keys(containerRecounts).every(
      (containerId) => {
        return (
          containerRecounts[containerId].full_recount !== null &&
          containerRecounts[containerId].full_recount !== "" &&
          Object.values(containerRecounts[containerId].open_bottles).every(
            (bottle) => bottle.reweight !== null && bottle.reweight !== ""
          )
        );
      }
    );

    if (everyContainerHasARecount) {
      // Are there any differences between recounts and original
      getRecountDifferences();
    } else {
      console.log("Recount is missing for a container/bottle");
      // Display some error message
    }
  };

  const getRecountDifferences = () => {
    let result = [];
    Object.keys(containerRecounts).forEach(function (id) {
      let container = auditReport.alcohols[currentBrandIndex].containers.find(
        (element) => parseInt(element.id) === parseInt(id)
      );
      let recountObject = {
        size: container.size,
        open_bottles: [],
        confirm: false,
      };
      if (
        parseInt(container.full_original_count) !==
        parseInt(containerRecounts[id].full_recount)
      ) {
        recountObject = {
          ...recountObject,
          original_count: parseInt(container.full_original_count),
          recount: containerRecounts[id].full_recount,
          confirm: true,
        };
      }
      Object.keys(containerRecounts[id].open_bottles).forEach(function (
        bottleId
      ) {
        let bottle = container.open_bottles.find(
          (bottle) => parseInt(bottle.id) === parseInt(bottleId)
        );
        if (
          parseFloat(bottle.weight) !==
          parseFloat(containerRecounts[id].open_bottles[bottleId])
        ) {
          recountObject.open_bottles = [
            ...recountObject.open_bottles,
            {
              weight: parseInt(bottle.weight),
              reweight: containerRecounts[id].open_bottles[bottleId],
            },
          ];
          recountObject.confirm = true;
        }
      });
      if (recountObject.confirm) {
        result.push(recountObject);
      }
    });
    setRecountDifferences(result);
  };

  const cycleToNextBrand = () => {
    if (currentBrandIndex + 1 === auditReport.alcohols.length) {
      setIsFinished(true);
      setIsLoading(false);
    } else {
      setCurrentBrandIndex(currentBrandIndex + 1);
    }
  };

  if (isFinished) return <FinishedMessage />;

  const handleConfirm = () => {
    submitRecounts().then(() => closeModal());
  };

  return (
    <div id="background">
      <div id="audit-report">
        <div id="venue-information">
          <p className="venue-name">{auditReport.venue.name}</p>
        </div>
        <AlcoholBrandCard
          alcoholBrand={auditReport.alcohols[currentBrandIndex]}
          containerRecounts={containerRecounts}
          setContainerRecounts={setContainerRecounts}
          handleChange={handleChange}
          numOfBrands={auditReport.alcohols.length}
          currentBrandNum={currentBrandIndex + 1}
        />
      </div>
      <div id="submit-recount-section">
        <button onClick={handleSubmit}>Submit Recount</button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          recountDifferences={recountDifferences}
        >
          <ConfirmationModal
            recountDifferences={recountDifferences}
            onCancel={closeModal}
            onConfirm={handleConfirm}
          />
        </Modal>
      </div>
    </div>
  );
};

export default AuditReport;

// const ACTION_ICONS = {
//   exclamationPair: ["0021", "0021"],
//   wineGlass: ["1F377"],
//   magnifyingGlass: ["1F50D"],
//   bin: ["1F5D1"],
//   bell: ["1F514"],
//   movingMail: ["1F585"],
//   handUp: ["1F590"],
//   chatBubble: ["1F5E9"],
// };

// const MOCK_AUDIT_REPORT = {
//   id: 11,
//   venue: {
//     name: "The Office",
//   },
//   auditor: {
//     id: 9,
//     first_name: "Aud",
//     last_name: "Itor",
//     email: "auditor@email.com",
//     created_at: "2021-05-09T07:20:48.907Z",
//     updated_at: "2021-05-09T07:20:48.907Z",
//   },
//   alcohols: [
//     {
//       id: 22,
//       name: "J. Roget Spumante",
//       class_name: "Champagne",
//       category: "Sparkling",
//       actions: [],
//       special_notes: [],
//       containers: [
//         {
//           id: 3,
//           size: "750ml",
//           full_original_count: 30,
//           full_recount: null,
//           actions: ["!!"],
//           special_notes: [],
//           open_bottles: [
//             {
//               id: 6,
//               weight: "10.0",
//               reweight: 1,
//             },
//             {
//               id: 8,
//               weight: "1081.0",
//               reweight: 1,
//             },
//             {
//               id: 10,
//               weight: "753.0",
//               reweight: 1,
//             },
//             {
//               id: 12,
//               weight: "984.0",
//               reweight: 1,
//             },
//           ],
//         },
//         {
//           id: 4,
//           size: "1L",
//           full_original_count: 30,
//           full_recount: null,
//           actions: ["!!"],
//           special_notes: [],
//           open_bottles: [
//             {
//               id: 8,
//               weight: "200.0",
//               reweight: 1,
//             },
//             {
//               id: 10,
//               weight: "300.0",
//               reweight: 1,
//             },
//             {
//               id: 12,
//               weight: "400.0",
//               reweight: 1,
//             },
//           ],
//         },
//       ],
//     },
//     {
//       id: 107,
//       name: "Grey Goose",
//       class_name: "Liquor",
//       category: "Vodka",
//       actions: [ACTION_ICONS.wineGlass, ACTION_ICONS.handUp],
//       special_notes: [
//         "Brand special note example 1",
//         "Brand special note example 2",
//         "Brand special note example 3",
//       ],
//       containers: [
//         {
//           id: 28,
//           size: "750ml",
//           full_original_count: 30,
//           full_recount: null,
//           actions: [ACTION_ICONS.chatBubble, ACTION_ICONS.bin],
//           special_notes: ["Special note example 1", "Special note example 2"],
//           open_bottles: [
//             {
//               id: 1001,
//               weight: "895.0",
//               reweight: null,
//             },
//             {
//               id: 1002,
//               weight: "1081.0",
//               reweight: null,
//             },
//             {
//               id: 1003,
//               weight: "753.0",
//               reweight: null,
//             },
//             {
//               id: 1004,
//               weight: "984.0",
//               reweight: null,
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };
