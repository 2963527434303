import React from "react";

import { renderUnicode } from "./ContainersRecountTable";

import "./BrandInformationBanner.css";

export default function BrandInformationBanner({
  name,
  className,
  category,
  actions,
  special_notes,
  numOfBrands,
  currentBrandNum,
}) {
  return (
    <div id="information-banner">
      <p id="information-className-category">
        {className} - {category}
      </p>
      <p id="information-brand-name">{name}</p>
      <p>
        ({currentBrandNum} of {numOfBrands})
      </p>
      <div id="brand-action-icons">
        {actions.map((actionList) =>
          actionList.map((action) => <span>{renderUnicode(action)}</span>)
        )}
      </div>
      <div id="brand-special-notes">
        {special_notes.map((note) => (
          <p>{note}</p>
        ))}
      </div>
    </div>
  );
}
