import React from 'react'
import { Alert, Button, Card, Checkbox, Modal, Typography } from "antd4";

import { renderUnicode } from "../AuditReport/ContainersRecountTable";
import { BASE_URL } from '../../Helpers/const';
import { useHistory } from 'react-router-dom';
import EditModal from './EditModal';

export default function ScheduleDetails({ schedule, venue, auditor, updateDetails }) {
  console.log("🚀 >> ScheduleDetails >> schedule", schedule)
  const history = useHistory()
  const [isEditModalVisible, setIsEditModalVisible] = React.useState(false)
  const [successVisible, setSuccessVisible] = React.useState(false);
  const [failureVisible, setFailureVisible] = React.useState(false);

  const showEditModal = () => setIsEditModalVisible(true)
  const hideEditModal = () => setIsEditModalVisible(false)

  const handleSuccessAlert = () => {
    setSuccessVisible(!successVisible);
  };

  const handleFailureAlert = () => {
    setFailureVisible(!failureVisible);
  };
  
  const handleDelete = () => {
    fetch(BASE_URL + `/audit_schedules/${schedule.id}`,
      { 
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      }
    )
    .then((res) => res.json())
    .then(() => history.push("/admin"))
  }

  return (
    <>
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography.Text strong>{venue.name}</Typography.Text>
              <br />
              <Typography.Text type="secondary">{`${auditor.first_name} ${auditor.last_name}`}</Typography.Text>
            </div>

          </div>
        }
        style={{ margin: 30 }}
      >
        <Typography.Text>
          Every {schedule.frequency_in_weeks}{" "}
          {schedule.frequency_in_weeks > 1 ? "weeks" : "week"}
        </Typography.Text>
        <br />
        <br />
        <Typography.Text>{schedule.time_of_day}</Typography.Text>
        <br />
        <br />
        <Checkbox disabled checked={schedule.monday}></Checkbox>
        <Typography.Text> Monday</Typography.Text>
        <br />
        <Checkbox disabled checked={schedule.tuesday}></Checkbox>
        <Typography.Text> Tuesday</Typography.Text>
        <br />
        <Checkbox disabled checked={schedule.wednesday}></Checkbox>
        <Typography.Text> Wednesday</Typography.Text>
        <br />
        <Checkbox disabled checked={schedule.thursday}></Checkbox>
        <Typography.Text> Thursday</Typography.Text>
        <br />
        <Checkbox disabled checked={schedule.friday}></Checkbox>
        <Typography.Text> Friday</Typography.Text>
        <br />
        <Checkbox disabled checked={schedule.saturday}></Checkbox>
        <Typography.Text> Saturday</Typography.Text>
        <br />
        <Checkbox disabled checked={schedule.sunday}></Checkbox>
        <Typography.Text> Sunday</Typography.Text>
        <br />
        <br />
        <div>
          <Button 
            style={{ width: "100%" }}
            onClick={showEditModal}
          >
            Edit {renderUnicode("1F589")}
          </Button>
          <br />
          <Button 
            danger
            style={{ width: "100%" }}
            onClick={() => Modal.warning({
              closable: true,
              maskClosable: true,
              title: "Delete audit schedule",
              content: "Are you sure you'd like to delete this schedule?",
              okText: "Delete",
              onOk: handleDelete,
            })}
          >
            Delete {renderUnicode("1F5D1")}
          </Button>
        </div>
        {successVisible && (
          <Alert
            type="success"
            message="Schedule Updated"
            showIcon
            closable
            style={{ width: "fit-content", margin: "auto" }}
            onClose={handleSuccessAlert}
          />
        )}
        {failureVisible && (
          <Alert
            type="warning"
            message="Schedule couldn't be updated"
            showIcon
            closable
            onClose={handleFailureAlert}
          />
        )}

      </Card>

      <EditModal 
        isEditModalVisible={isEditModalVisible}
        hideEditModal={hideEditModal} 
        schedule={schedule} 
        updateDetails={updateDetails}
        handleFailureAlert={handleFailureAlert}
        handleSuccessAlert={handleSuccessAlert}
      />
    </>
  )
}
