import React from "react";
import { renderUnicode } from "./ContainersRecountTable";

export default function ConfirmationModal({
  recountDifferences,
  onCancel,
  onConfirm,
}) {
  return (
    <div
      style={{
        textAlign: "center",
        position: "relative",
        height: "100%",
      }}
    >
      <h4>
        <i>
          It looks like some of the recounts don't match the original counts.
          Double check the new values before confirming.
        </i>
      </h4>
      <br />
      <h5>
        (<i>Original value</i> {renderUnicode("2192")} <b>New value)</b>
      </h5>
      <br />
      <div
        style={{
          overflow: "scroll",
          height: "60%",
          backgroundColor: "#EEEEEE",
          borderRadius: "5px",
        }}
      >
        {recountDifferences.map((containerDifferences) => {
          return (
            <>
              <h2>{containerDifferences.size}</h2>
              <h4>Full count: </h4>
              <p>
                <i>{containerDifferences.original_count}</i>{" "}
                {renderUnicode("2192")} <b>{containerDifferences.recount}</b>
              </p>
              <h4>Open bottles: </h4>

              {containerDifferences.open_bottles.map((bottle) => {
                console.log("🚀 >> {recountDifferences.map >> bottle", bottle);
                return (
                  <p>
                    <i>{bottle.weight.toFixed(1)}</i> {renderUnicode("2192")}{" "}
                    <b>{bottle.reweight.toFixed(1)}</b>
                  </p>
                );
              })}
              <br />
            </>
          );
        })}
      </div>
      <div style={{ position: "absolute", width: "100%", bottom: "0px" }}>
        <button style={{ width: "100%" }} onClick={onConfirm}>
          Confirm
        </button>
        <button style={{ width: "100%" }} onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
}
