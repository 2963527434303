import React, { useState } from "react";
import {
  Card,
  Select,
  DatePicker,
  Alert,
  Button,
  Typography,
  Form,
} from "antd";
import { useAuthValue } from "../../Auth/AuthContext";
import { CREATE_URL } from "../../Helpers/const";
import moment from "moment";
import { call } from "../../Helpers/Call";
const { Option } = Select;
const { Text } = Typography;
const { Item } = Form;

const CreateReport = ({ form }) => {
  const { user, token } = useAuthValue();
  const [loading, setLoading] = useState(false);
  const [venue_id, setVenue] = useState();
  const [content_date, setDate] = useState();
  const [success, setSuccess] = useState(false);
  const { getFieldDecorator, validateFields } = form;

  const allVenues = user.venues || [];
  const create = async () => {
    setLoading(true);
    const body = { venue_id, content_date, user_id: user.id };
    const onSuccess = () => setSuccess(true);
    await call(CREATE_URL, "POST", token, onSuccess, body);
    setLoading(false);
  };

  const submit = (e) => {
    e.preventDefault();
    validateFields((err) => {
      if (!err) create();
    });
  };

  const alphabetize = (a, b) => {
    return a.name.localeCompare(b.name);
  };

  return (
    <>
      {allVenues.length ? (
        <Form onSubmit={submit} colon={false} hideRequiredMark>
          <Card
            style={{ width: 400, maxWidth: "100%", margin: "auto" }}
            bodyStyle={{
              padding: "12px 24px 12px 48px",
              minHeight: 600,
            }}
            bordered={false}
          >
            <Item label={<Text strong>Select Venue</Text>}>
              {getFieldDecorator("venue", {
                rules: [{ required: true, message: "Venue is required" }],
              })(
                <Select
                  showSearch
                  style={{ width: 280 }}
                  placeholder={`ex. "${allVenues[0].name.replace(
                    "&amp;",
                    "&"
                  )}"`}
                  optionFilterProp="children"
                  onChange={setVenue}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allVenues.sort(alphabetize).map(({ name, id }, i) => {
                    return (
                      <Option key={`${i}`} value={id}>
                        {name.replace("&amp;", "&")}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Item>
            <Item label={<Text strong>Select Date</Text>}>
              <DatePicker
                onChange={(date, dateString) => setDate(dateString)}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
                placeholder="Enter date or select below"
                style={{ minWidth: 280 }}
                allowClear={true}
              />
            </Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ position: "absolute", bottom: 50, width: 280 }}
              loading={loading}
            >
              {loading ? "Generating report..." : "Create"}
            </Button>
            <br />
            {success && (
              <Alert
                type="success"
                style={{ position: "absolute", bottom: 0, width: 280 }}
                message="Your report has been successfully created."
                showIcon
              />
            )}
          </Card>
        </Form>
      ) : (
        <Alert
          type="warning"
          message="No venues found. Please contact your manager for access."
          showIcon
          style={{ width: "fit-content", margin: "auto" }}
        />
      )}
    </>
  );
};

const CreateReportForm = Form.create({ name: "create_report" })(CreateReport);
export default CreateReportForm;
