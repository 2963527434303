import React from 'react'
import { Layout, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import { useAuthValue } from '../Auth/AuthContext'
import { PAGES } from '../Helpers/const'
import SideNav from './SideNav'
import { ResetPassword, AuthCard, Approval } from '../Views'

const { Content } = Layout
const { Title } = Typography

const headerStyle = {
  color: 'rgba(38, 50, 75, 0.85)',
  fontWeight: 500,
  padding: '22px 30px',
  backgroundColor: 'rgba(0,0,0,0.05)',
}

const BrandWrap = ({ children }) => {
  const { loggedIn } = useAuthValue()
  const location = useLocation()
  const path = location.pathname

  const { APPROVE, RESET, REPORT, VENUE } = PAGES

  const getTitle = () => {
    const page = path === '/' ? 'CREATE' : path.substring(1).toUpperCase()
    return PAGES[page] ? PAGES[page].title : 'Page Not Found'
  }

  if (path === APPROVE.route)
    return (
      <Layout style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
        <Approval />
      </Layout>
    )

  if (path === RESET.route)
    return (
      <Layout style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
        <ResetPassword />
      </Layout>
    )

  return (
    <Layout style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      {!loggedIn && <AuthCard />}
      {loggedIn && (
        <>
          <SideNav />
          <Content>
            {path !== REPORT.route && path !== VENUE.route && (
              <Title level={4} style={headerStyle}>
                {getTitle()}
              </Title>
            )}
            {children}
          </Content>
        </>
      )}
    </Layout>
  )
}

export default BrandWrap
