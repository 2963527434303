import React from 'react'
import { Row, Icon, Typography } from 'antd'
import { ReactComponent as Logo } from '../../Styles/beer.svg'
import { lightBlue, topaz } from '../../Helpers/const'
const { Title, Text } = Typography
const logoStyle = { width: 30, margin: 5 }
const checkStyle = {
  backgroundColor: '#fff',
  borderRadius: '50%',
  border: '1px white solid',
}

const middleIcon = (reject) => {
  return reject ? (
    <Icon type='close-circle' theme='filled' style={{ ...checkStyle, color: topaz }} />
  ) : (
    <Icon type='check-circle' theme='filled' style={{ ...checkStyle, color: lightBlue }} />
  )
}

export const successIcon = (reject) => (
  <Row style={{ marginBottom: 24 }}>
    <Icon style={{ ...logoStyle, transform: 'scale(-1,1)' }} component={() => <Logo />} />
    {middleIcon(reject)}
    <Icon style={logoStyle} component={() => <Logo />} />
  </Row>
)

const SuccessMsg = ({ title, subtitle, reject }) => {
  return (
    <section style={{ paddingBottom: 24, textAlign: 'center' }}>
      {successIcon(reject)}
      <Title level={4}>{title}</Title>
      <Text>{subtitle}</Text>
    </section>
  )
}

export default SuccessMsg
