import React, { useState, useEffect } from 'react'
import { Table, Menu, Typography, Dropdown, Button, Modal } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useAuthValue } from '../../Auth/AuthContext'
import { PAGES, USERS_URL } from '../../Helpers/const'
import { call } from '../../Helpers/Call'
import Venues from './AssignVenues'
const { Text } = Typography
const { confirm } = Modal

const UsersAdmin = ({ venueList }) => {
  const { token, user } = useAuthValue()
  const [users, setUsers] = useState([])
  const [expanded, setExpanded] = useState([])

  const deleteUser = async id => {
    const url = `${USERS_URL}/${id}`
    const onSuccess = data => setUsers(data)
    call(url, 'DELETE', token, onSuccess)
  }

  const openWarning = record => {
    confirm({
      title: <Text strong>Delete {record.name}?</Text>,
      content:
        'Are you sure you want to permanently erase this user? All of their reports and data will be lost.',
      okText: 'Delete',
      okType: 'primary',
      onOk: () => deleteUser(record.id),
      maskClosable: true,
      autoFocusButton: 'ok',
    })
  }

  const dateSort = item => new Date(item.lastReport ? item.lastReport.created_at : '1970-01-01')

  const menu = record => {
    return (
      <Menu>
        <Menu.Item key={'view'}>
          <Link to={`${PAGES.REPORTS.route}?uid=${record.id}`}>View reports</Link>
        </Menu.Item>
        <Menu.Item key={'venues'} onClick={() => setExpanded([record.id])}>
          Assign venues
        </Menu.Item>
        <Menu.Item
          key={record.id}
          disabled={record.id === user.id}
          onClick={() => openWarning(record)}>
          Delete user
        </Menu.Item>
      </Menu>
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <span style={{ display: 'grid' }}>
          <Text strong>{name}</Text>
          <Text type='secondary'>{record.email}</Text>
        </span>
      ),
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
    },
    {
      title: <Text style={{ fontSize: 12 }}>Total reports</Text>,
      dataIndex: 'reports',
      key: 'reports',
      width: 130,
      render: (reports, record) =>
        reports && (
          <Button type='link' style={{ width: '100%', textAlign: 'center' }}>
            <Link to={`${PAGES.REPORTS.route}?uid=${record.id}`}>{reports.length}</Link>
          </Button>
        ),
      sorter: (a, b) => a.reports.length - b.reports.length,
    },
    {
      title: 'Last report',
      dataIndex: 'reports',
      key: 'lastReports',
      render: (reports, record) => {
        if (reports && reports.length) {
          const lastReport = reports[reports.length - 1]
          return (
            <Button type='link'>
              <Link to={`${PAGES.REPORT.route}?uid=${record.id}&report=${lastReport.id}`}>
                {moment(lastReport.created_at).fromNow()}
              </Link>
            </Button>
          )
        }
      },
      sorter: (a, b) => dateSort(a) - dateSort(b),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Dropdown overlay={menu(record)} trigger={['click']}>
            <Button type='link' style={{ fontSize: 20 }}>
              ...
            </Button>
          </Dropdown>
        </span>
      ),
    },
  ]

  useEffect(() => {
    const getUsers = async () => {
      const resp = await fetch(USERS_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
      const data = await resp.json()
      setUsers(data)
    }
    if (!users.length) getUsers()
  })

  return (
    <>
      <Table
        columns={columns}
        dataSource={users}
        style={{ width: 800, margin: 'auto' }}
        pagination={false}
        rowKey={'id'}
        rowClassName={'users'}
        bordered
        expandedRowRender={record => (
          <Venues user={record} setExpanded={setExpanded} venueList={venueList} />
        )}
        expandedRowKeys={expanded}
      />
    </>
  )
}

export default UsersAdmin
