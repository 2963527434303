import { message } from 'antd'
import { BASE_URL } from "../Helpers/const";


export const call = async (url, req, token, onSuccess, body, onError) => {
  const includeBody = body ? { body: JSON.stringify(body) } : null

  const resp = await fetch(url, {
    method: req,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    ...includeBody,
  })
  const data = await resp.json()

  if (data.error) onError ? onError() : message.error(`Error: ${data.error}`)
  else onSuccess(data)
}

export const makeARequest = async ({ path, method, body }) => {
  const includeBody = body ? { body: JSON.stringify(body) } : null

  const resp = await fetch(BASE_URL + path, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('auth_token'),
    },
    ...includeBody,
  })
  const data = await resp.json()
  if (data.error) {
    message.error(`Error: ${data.error}`)
  } else {
    return data
  }
}
