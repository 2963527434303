import React, { useState } from 'react'
import { Card, Typography, Row, Icon } from 'antd'
import { Redirect } from 'react-router-dom'
import { useAuthValue } from '../../Auth/AuthContext'
import { PAGES, LOGIN_URL, USERS_URL, carrotLight, PASSWORD_URL } from '../../Helpers/const'
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'
import ForgotPwForm from './ForgotPwForm'
import { ReactComponent as Logo } from '../../Styles/beer.svg'
import { call } from '../../Helpers/Call'
const { Title } = Typography
const logoStyle = { width: 30, marginRight: 10 }
const coverStyle = {
  backgroundColor: carrotLight[9],
  textAlign: 'center',
  padding: '1em 0px 0.5em',
  color: '#fff',
  letterSpacing: 1,
}

const urls = {
  login: LOGIN_URL,
  signup: USERS_URL,
  forgot: `${PASSWORD_URL}/forgot`,
}

const titles = {
  login: 'Welcome to NLG',
  signup: 'Create Account',
  forgot: 'Forgot Your Password?',
}

const AuthCard = ({ location }) => {
  const { login, loggedIn } = useAuthValue()
  const [form, setForm] = useState('login')
  const [load, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const switchView = newView => setForm(newView)

  const submit = async (values, action) => {
    setLoading(true)
    const url = urls[action]
    const onSuccess = data => {
      action === 'login' ? login(data) : setSuccess(true)
    }
    await call(url, 'POST', null, onSuccess, values)
    setLoading(false)
  }

  if (!loggedIn) {
    return (
      <Card
        cover={
          !success && (
            <Row style={coverStyle}>
              <Title level={4} style={{ fontWeight: 400, color: 'black' }}>
                <Icon style={logoStyle} component={() => <Logo />} />
                {titles[form]}
              </Title>
            </Row>
          )
        }
        style={{ width: 400, maxWidth: '100%', margin: '5% auto', border: '1px solid black' }}
        bodyStyle={{ paddingBottom: 0 }}>
        {form === 'signup' && (
          <SignUpForm submit={submit} switchView={switchView} load={load} success={success} />
        )}
        {form === 'login' && <LoginForm submit={submit} switchView={switchView} load={load} />}
        {form === 'forgot' && (
          <ForgotPwForm submit={submit} switchView={switchView} load={load} success={success} />
        )}
      </Card>
    )
  } else return <Redirect to={{ pathname: PAGES.CREATE.ROUTE, state: { from: location } }} />
}
export default AuthCard
