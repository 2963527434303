import React, { useState } from 'react'
import { Card, Typography, Row, Icon } from 'antd'
import { carrotLight, PASSWORD_URL, PAGES } from '../../Helpers/const'
import ChangePw from '../Account/ChangePw'
import { useLocation, Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../Styles/beer.svg'
import SuccessMsg from './SuccessMsg'
import { call } from '../../Helpers/Call'
const { Title } = Typography
const logoStyle = { width: 30, marginRight: 10 }
const coverStyle = {
  backgroundColor: carrotLight[9],
  textAlign: 'center',
  padding: '1em 0px 0.5em',
  color: '#fff',
  letterSpacing: 1,
}

const ResetPassword = () => {
  const [success, setSuccess] = useState(false)
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const email = query.get('email')
  const token = query.get('token')

  const saveChange = async formValues => {
    const values = {
      ...formValues,
      email,
      token,
    }
    const url = `${PASSWORD_URL}/reset`
    const onSuccess = () => setSuccess(true)
    call(url, 'POST', null, onSuccess, values)
  }

  return (
    <Card
      cover={
        !success && (
          <Row style={coverStyle}>
            <Title level={4} style={{ fontWeight: 400, color: 'black' }}>
              <Icon style={logoStyle} component={() => <Logo />} />
              Reset Password
            </Title>
          </Row>
        )
      }
      style={{ width: 400, maxWidth: '100%', margin: '5% auto', border: '1px solid black' }}
      bodyStyle={{ paddingBottom: 0 }}>
      {success && (
        <SuccessMsg
          title={'Success!'}
          subtitle={
            <>
              You may now <Link to={PAGES.LOGIN.route}>log in</Link> with your new password.
            </>
          }
        />
      )}
      {!success && <ChangePw saveChange={saveChange} />}
    </Card>
  )
}
export default ResetPassword
