import React, { useState, useEffect } from 'react'
import { message, Layout } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useAuthValue } from '../../Auth/AuthContext'
import { Redirect, useLocation } from 'react-router-dom'
import { PAGES, VENUES_URL, carrot } from '../../Helpers/const'
import { call } from '../../Helpers/Call'
import Venue from './Venue'
const { Content } = Layout

const VenuePage = () => {
  const { token } = useAuthValue()
  const [venue, setVenue] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()

  useEffect(() => {
    const getVenue = async () => {
      setLoading(true)
      if (query.get('id')) {
        const venueId = Number(query.get('id'))
        const url = `${VENUES_URL}/summary/${venueId}`
        const onSuccess = data => setVenue(data)
        const onError = () => {
          setError(true)
          setVenue({})
          message.error('Something went wrong')
        }
        await call(url, 'GET', token, onSuccess, false, onError)
        setLoading(false)
      }
    }
    if (!venue && !error) getVenue()
  })

  if (error) return <Redirect to={{ pathname: PAGES.ADMIN.route }} />
  else
    return (
      <Content style={{ padding: 25 }}>
        {loading ? (
          <LoadingOutlined spin style={{ fontSize: 40, color: carrot, margin: 'auto' }} />
        ) : (
          <Venue venue={venue} />
        )}
      </Content>
    )
}

export default VenuePage
