import React from "react";
import { Form, Input, Modal } from "antd4";
import { BASE_URL } from "../../../Helpers/const";

const NewAuditorModal = ({
  isModalVisible,
  setIsModalVisible,
  addNewAuditor,
}) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const handleSubmit = () => {
    fetch(BASE_URL + "/auditors", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({
        email,
        first_name: firstName,
        last_name: lastName,
      }),
    })
      .then((res) => res.json())
      .then(({ auditor: newAuditor }) => {
        addNewAuditor(newAuditor);
        setIsModalVisible(false);
      })
      .catch(({ error }) => console.error(error));
  };

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleSubmit}
      onCancel={() => setIsModalVisible(false)}
    >
      <Form>
        <Form.Item
          name="First name"
          label="First name"
          rules={[{ required: true }]}
        >
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="Last name"
          label="Last name"
          rules={[{ required: true }]}
        >
          <Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Item>
        <Form.Item name="Email" label="Email" rules={[{ required: true }]}>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewAuditorModal;
