import React, { useState, useEffect } from "react";
import { Table, Avatar, Typography } from "antd";
import { useAuthValue } from "../../Auth/AuthContext";
import moment from "moment";
import { Redirect, useLocation } from "react-router-dom";
import { PAGES, REPORTS_URL, xsAvatar } from "../../Helpers/const";
import Report from "./Report";
import { call } from "../../Helpers/Call";
const { Text } = Typography;

const Reports = () => {
  const { user, token } = useAuthValue();
  const [reports, setReports] = useState();
  const [venues, setVenues] = useState([]);
  const [creator, setCreator] = useState("");

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const creatorId = query.get("uid") ? Number(query.get("uid")) : user.id;

  useEffect(() => {
    const getReports = async () => {
      const url = `${REPORTS_URL}/${creatorId}`;
      const onSuccess = (data) => {
        if (data.user) setCreator(data.user.name);
        if (data.reports) {
          setReports(data.reports);
          setVenues(
            data.reports.map((report) => {
              if (!venues.includes(report.venue.name)) return report.venue.name;
              else return null;
            })
          );
        }
      };
      const onError = () => setCreator("User not found");
      call(url, "GET", token, onSuccess, null, onError);
    };

    if (!reports && creatorId) getReports();
  }, [token, creatorId, reports, venues]);

  const venueFilters = () => {
    let uniqueVens = [];
    if (venues) {
      venues.forEach((venue) => {
        if (!uniqueVens.includes(venue)) uniqueVens.push(venue);
      });
      return uniqueVens.map((venue) => ({
        value: venue,
        text: venue.replace("&amp;", "&"),
      }));
    }
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      sorter: (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated),
      render: (dateCreated) => moment(dateCreated).format("MMM Do YYYY"),
    },
    {
      title: "Venue Name",
      dataIndex: "venue",
      key: "venue",
      filters: venueFilters(),
      onFilter: (value, record) => record.venue.name.indexOf(value) === 0,
      render: (venue) => venue.name.replace("&amp;", "&"),
    },
    // {
    //   title: 'Sent',
    //   dataIndex: 'dateSent',
    //   key: 'dateSent',
    //   render: dateSent =>
    //     dateSent ? moment(dateSent).format('MMM Do YYYY') : <Tag color='gold'>Draft</Tag>
    // }
  ];

  if (user.role === "admin" || user.id === creatorId) {
    return (
      <>
        <Avatar icon="user" style={xsAvatar} />
        <Text style={{ fontSize: 15 }} strong>
          {creatorId ? creator : "User not found"}
        </Text>
        <Table
          style={{ maxWidth: 800, margin: "auto" }}
          bordered
          dataSource={reports}
          columns={columns}
          rowKey={"id"}
          pagination={false}
          expandedRowRender={(report) => <Report report={report} />}
        />
      </>
    );
  } else
    return <Redirect to={{ pathname: `${PAGES.REPORTS.route}/${user.id}` }} />;
};

export default Reports;
