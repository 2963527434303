import React from "react";
import { Form, Input, Button } from "antd4";
import { makeARequest } from "../Helpers/Call";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const EmailForm = ({ reportId, setDisplayEmailForm }) => {
  const onFinish = ({ emails, subject, cc, bcc }) => {
    makeARequest({
      path: `/reports/${reportId}/forward`,
      method: "POST",
      body: { emails, subject, cc, bcc },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      {...layout}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Email(s)"
        name="emails"
        rules={[
          {
            required: true,
            message:
              "Please input at least one email you want to send the report to",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Subject"
        name="subject"
        rules={[
          {
            required: true,
            message: "Please input a subject line for the emails",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Cc" name="cc">
        <Input />
      </Form.Item>

      <Form.Item label="Bcc" name="bcc">
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button onClick={() => setDisplayEmailForm(false)}>Cancel</Button>
        <Button type="primary" htmlType="submit">
          Send report
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailForm;
