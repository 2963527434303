import React from "react";
import { Button, Row, Table, Typography } from "antd4";
import NewAuditScheduleModal from "./NewAuditScheduleModal";
import { Link } from "react-router-dom";

const COLUMNS = [
  {
    title: <Typography.Text>Venue</Typography.Text>,
    dataIndex: "venue_name",
    sorter: false,
    render: (venueName, schedule) => (
      <Link to={`/audit-schedule/${schedule.id}`}>{venueName}</Link>
    ),
  },
  {
    title: <Typography.Text>When</Typography.Text>,
    dataIndex: "time_of_day",
    sorter: (a, b) => {
      const [hoursA, minutesA] = a.time_of_day.split(":");
      const [hoursB, minutesB] = b.time_of_day.split(":");

      const dateAEpoch = new Date("2000-01-01").setHours(
        parseInt(hoursA),
        parseInt(minutesA),
        0,
        0
      );
      const dateBEpoch = new Date("2000-01-01").setHours(
        parseInt(hoursB),
        parseInt(minutesB),
        0,
        0
      );
      return dateAEpoch > dateBEpoch;
    },
    render: (_, schedule) => (
      <Typography.Text>
        {schedule.weekday} {schedule.time_of_day}
      </Typography.Text>
    ),
  },
  {
    title: (
      <Typography.Text style={{ alignSelf: "right" }}>
        Frequency
      </Typography.Text>
    ),
    dataIndex: "frequency_in_weeks",
    sorter: (a, b) => a.frequency_in_weeks - b.frequency_in_weeks,
    render: (frequencyInWeeks) => (
      <Typography.Text style={{ alignSelf: "right" }}>
        {frequencyInWeeks} {frequencyInWeeks > 1 ? "weeks" : "week"}
      </Typography.Text>
    ),
  },
];

const AuditorSchedules = ({
  auditorId,
  schedules,
  venues,
  addNewAuditSchedule,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <div style={{ padding: 30 }}>
      <Row
        style={{
          textAlign: "right",
          maxWidth: 850,
          margin: "auto",
        }}
        onClick={() => setIsModalVisible(true)}
      >
        <Button type="primary">Add schedule</Button>
      </Row>
      <Table
        rowKey="id"
        dataSource={schedules}
        // showHeader={false}
        pagination={false}
        columns={COLUMNS}
        style={{ paddingLeft: 50 }}
      />
      <NewAuditScheduleModal
        auditorId={auditorId}
        venues={venues}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        addNewAuditSchedule={addNewAuditSchedule}
      />
    </div>
  );
};

export default AuditorSchedules;
