import React from "react";
import { Typography } from 'antd'
const { Text } = Typography

const Phrase = ({sentence}) => {

  return (
    <>
    <Text>
      { sentence }
    </Text>
    <br />
    <br />
    </>
  )
}
export default Phrase