export const PAGES = {
  ADMIN: {
    route: "/admin",
    nav: "Admin",
    title: "Administrator settings",
  },
  ACCOUNT: {
    route: "/account",
    nav: "Account",
    title: "Account settings",
  },
  APPROVE: {
    route: "/approve",
    nav: "Approval",
    title: "Admin Approval",
  },
  REPORTS: {
    route: "/reports",
    nav: "Reports",
    title: "Report history",
  },
  REPORT: {
    route: "/report",
    nav: "Report",
    title: "Report",
  },
  VENUE: {
    route: "/venue",
    nav: "Venue",
    title: "Venue",
  },
  CREATE: {
    route: "/create",
    nav: "New Report",
    title: "Generate new report",
  },
  LOGIN: {
    route: "/login",
    nav: "Log In",
    title: "",
  },
  RESET: {
    route: "/reset",
    nav: "Reset Password",
    title: "Reset Password",
  },
  AUDIT_SCHEDULES: {
    route: "/audit-schedule/*",
    nav: "Audit Schedule",
    title: "Audit Schedule",
  },
};

const required = { required: true };

export const formRules = {
  name: { ...required, message: "Name required" },
  email: { ...required, message: "Email address required" },
  loginPw: { ...required, message: "Password is required" },
  signUpPw: {
    ...required,
    min: 6,
    message: "Password must be at least 6 characters",
  },
  changePw: {
    ...required,
    min: 6,
    message: "New password must be at least 6 characters",
  },
  confirmPw: { ...required, message: "Passwords do not match" },
  accessCode: { ...required, message: "Access code is required" },
  clientId: { ...required, message: "Client ID is required" },
};

export const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const USERS_URL = `${BASE_URL}/users`;
export const CREATE_URL = `${BASE_URL}/request_report`;
export const REPORTS_URL = `${BASE_URL}/reports`;
export const VENUES_URL = `${BASE_URL}/venues`;
export const PASSWORD_URL = `${BASE_URL}/password`;
export const APPROVAL_URL = `${BASE_URL}/approve`;

const bit = "http://bit.ly/";

export const EXTERNAL_URLS = {
  ALL_REPORTS: `${bit}Bevinco2020`,
  STAFF_REPORT: `${bit}BevincoStaffReport`,
  VAR_REPORT: `${bit}BevincoDetailedVarianceReport`,
  INT_REPORT: `${bit}BevincoInteliParReport`,
};

// COLOURS

export const carrot = "#f59b46"; // logo
export const lightBlue = "#64adf5"; // theme colour
export const dustyBlue = "#6C8EAD";
export const topaz = "#A23E48";
export const almond = "#fce5c7";
export const grey = "#8F857D";
export const palm = "#7DB82A";
export const charcoal = "#323b4e";

export const colorSet = [
  carrot,
  lightBlue,
  topaz,
  dustyBlue,
  palm,
  charcoal,
  grey,
];

export const carrotLight = [
  "#ffffff",
  "#fef5ed",
  "#fdebda",
  "#fce1c8",
  "#fbd7b5",
  "#facda3",
  "#f9c390",
  "#f8b97e",
  "#f7af6b",
  "#f6a559",
];
export const carrotDark = [
  "#f5a844",
  "#dd8c3f",
  "#c47c38",
  "#ac6d31",
  "#935d2a",
  "#7b4e23",
  "#623e1c",
  "#492e15",
  "#311f0e",
  "#180f07",
  "#000000",
];

export const xsAvatar = {
  margin: "10px 5px 15px 30px",
  color: "#fff",
  backgroundColor: carrot,
  width: 20,
  height: 20,
  lineHeight: "20px",
  fontSize: 12,
};
