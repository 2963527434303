import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Alert,
} from "antd4";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../Helpers/const";
import moment from "moment";
import ScheduleDetails from "./ScheduleDetails";

const tableColumns = [
  {
    align: "center",
    title: "Reports",
    render: (_, record) => {
      return (
        <Link key={record.id} to={`/audit/${record.id}?scrt=${record.secret}`}>
          {record.date_range}
        </Link>
      );
    },
  },
];

const yesterday = () => {
  return moment().subtract(1, "days").format("YYYY-MM-DD");
};

const AuditSchedule = () => {
  const [isGeneratingReport, setIsGeneratingReport] = React.useState(false);
  const [schedule, setSchedule] = React.useState(null);
  const [reports, setReports] = React.useState([]);
  const [auditor, setAuditor] = React.useState([]);
  const [venue, setVenue] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(yesterday());
  const { scheduleId } = useParams();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");
  const [successVisible, setSuccessVisible] = React.useState(false);
  const [failureVisible, setFailureVisible] = React.useState(false);

  React.useEffect(() => {
    fetch(BASE_URL + `/audit_schedules/${scheduleId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then(({ schedule, auditor, venue, audit_reports }) => {
        setSchedule(schedule);
        setAuditor(auditor);
        setVenue(venue);
        setReports(audit_reports);
      })
      .catch(console.error);
  }, [scheduleId]);

  const updateScheduleDetails = newSchedule => {
    setSchedule(newSchedule)
  }

  const runLatestAuditReport = () => {
    setIsGeneratingReport(true);
    fetch(BASE_URL + "/recount/run", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({
        schedule_id: scheduleId,
        date: selectedDate,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          setIsGeneratingReport(false);
          setError(response.error);
          handleFailureAlert();
        } else {
          setReports([...reports, response]);
          setIsGeneratingReport(false);
          setSuccess(true);
          handleSuccessAlert();
        }
      })
      .catch((err) => console.error(err));
  };

  const handleSuccessAlert = () => {
    setSuccessVisible(!successVisible);
  };

  const handleFailureAlert = () => {
    setFailureVisible(!failureVisible);
  };

  if (!schedule) return <></>;

  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <ScheduleDetails updateDetails={updateScheduleDetails} schedule={schedule} venue={venue} auditor={auditor}/>
      </Col>
      <Col span={16}>
        <Row
          style={{
            textAlign: "right",
            margin: "auto",
            padding: 20,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Input
            placeholder="2021-02-15"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
          <Button
            onClick={runLatestAuditReport}
            type="primary"
            loading={isGeneratingReport}
          >
            {isGeneratingReport ? "Generating report" : "Run latest check"}
          </Button>
          <br />
        </Row>
        {success && successVisible && (
          <Alert
            type="success"
            message="Your report has been successfully created."
            showIcon
            closable
            onClose={handleSuccessAlert}
          />
        )}
        {error && failureVisible && (
          <Alert
            type="warning"
            message={error}
            showIcon
            closable
            style={{ width: "fit-content", margin: "auto" }}
            onClose={handleFailureAlert}
          />
        )}
        <br />
        <Table columns={tableColumns} dataSource={reports} />
      </Col>
    </Row>
  );
};

export default AuditSchedule;
