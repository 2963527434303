import React from "react";
import BrandPhrases from "./BrandPhrases";
import Phrase from "./Phrase";
import { Typography, Divider } from "antd";
import { UpArrowIcon } from "../Assets/UpArrowIcon";
import { DownArrowIcon } from "../Assets/DownArrowIcon";
const { Title } = Typography;

const ClassInsights = ({ name, class_variances_and_phrases }) => {
  console.log(class_variances_and_phrases);
  const {
    current_period_percent_shortage,
    previous_period_percent_shortage,
    brands,
    class_phrases,
  } = class_variances_and_phrases;

  if (
    current_period_percent_shortage === null ||
    previous_period_percent_shortage === null
  )
    return null;

  const change =
    previous_period_percent_shortage < current_period_percent_shortage ? (
      <UpArrowIcon pixelSize={30} />
    ) : (
      <DownArrowIcon pixelSize={30} />
    );

  const listClassLevelInsights = () => {
    if (class_phrases.length === 0) return null;

    return (
      <section>
        {class_phrases.map((phrase) => (
          <Phrase sentence={phrase} />
        ))}
        <br />
      </section>
    );
  };

  const listBrandPhrases = () => {
    const brandNames = Object.keys(brands);
    if (brandNames.length === 0) return null;

    return (
      <section>
        <Title level={4}>Brand insights</Title>
        <br />
        {brandNames.map((brand) => {
          return <BrandPhrases key={brand} phrases={brands[brand]} />;
        })}
      </section>
    );
  };
  return (
    <>
      <section>
        <Title level={3} strong>
          {name} {change} from {previous_period_percent_shortage.toFixed(1)} %
        </Title>
      </section>
      {listClassLevelInsights()}
      {listBrandPhrases()}
      <Divider />
    </>
  );
};
export default ClassInsights;
