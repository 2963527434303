import React, { useState, useEffect } from 'react'
import { Card } from 'antd'
import { APPROVAL_URL } from '../../Helpers/const'
import { useLocation } from 'react-router-dom'
import SuccessMsg from './SuccessMsg'
import { call } from '../../Helpers/Call'

const Approval = () => {
  const [success, setSuccess] = useState(false)
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const token = query.get('token')

  const cardStyle = { width: 400, maxWidth: '100%', margin: '5% auto' }

  useEffect(() => {
    const submit = async () => {
      const url = `${APPROVAL_URL}?token=${token}`
      const onSuccess = data => setSuccess(data.approved ? 'approved' : 'rejected')
      call(url, 'GET', null, onSuccess)
    }
    if (!success) submit()
  })

  return (
    <Card style={{ ...cardStyle, border: '1px solid black' }} bodyStyle={{ paddingBottom: 0 }}>
      {success === 'approved' && (
        <SuccessMsg
          title={'Approved'}
          subtitle={
            <>
              You have approved this user.
              <br />
              They may now log into their account.
            </>
          }
        />
      )}
      {success === 'rejected' && (
        <SuccessMsg
          title={'Rejected'}
          subtitle={
            "You have rejected this user's attempt to create an account. If this rejection was made in error, the user will need to sign up and request approval again."
          }
          reject={true}
        />
      )}
    </Card>
  )
}
export default Approval
