import React, { useState } from 'react'
import { Form, Input, Button, Row } from 'antd'
import { formRules } from '../../Helpers/const'
import SuccessMsg from './SuccessMsg'
const { Item } = Form

const itemMargin = { marginBottom: 10 }

const SignUp = ({ submit, switchView, form, load, success }) => {
  const { getFieldDecorator, validateFields, getFieldValue } = form
  const [isMatch, setIsMatch] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) submit(values, 'signup')
    })
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback(formRules.confirmPw.message)
    } else callback()
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value && isMatch) {
      validateFields(['confirm'], { force: true })
    }
    callback()
  }

  const handleConfirmBlur = (e) => setIsMatch(isMatch || !!e.target.value)

  if (success) {
    return (
      <SuccessMsg
        title={'Account created.'}
        subtitle={
          'Your manager has been notified of your request. You will be able to log in after they approve your account.'
        }
      />
    )
  }

  return (
    <Form onSubmit={handleSubmit} hideRequiredMark={true}>
      <Item label='Name' style={itemMargin}>
        {getFieldDecorator('name', { rules: [formRules.name] })(<Input />)}
      </Item>
      <Item label='Email address' style={itemMargin}>
        {getFieldDecorator('email', { rules: [formRules.email] })(<Input type='email' />)}
      </Item>
      <Item label='Password' style={itemMargin} hasFeedback>
        {getFieldDecorator('password', {
          rules: [formRules.signUpPw, { validator: validateToNextPassword }],
        })(<Input.Password />)}
      </Item>
      <Item label='Confirm password' hasFeedback>
        {getFieldDecorator('password_confirmation', {
          rules: [formRules.confirmPw, { validator: compareToFirstPassword }],
        })(<Input.Password onBlur={handleConfirmBlur} />)}
      </Item>
      <Item>
        <Button type='primary' htmlType='submit' size='large' loading={load} block>
          Get Started
        </Button>
        <Row style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
          Or
          <Button type='link' style={{ padding: '0px 3px' }} onClick={() => switchView('login')}>
            log in
          </Button>
          to existing account.
        </Row>
      </Item>
    </Form>
  )
}

const SignUpForm = Form.create({ name: 'signUp' })(SignUp)
export default SignUpForm
