import React from "react";
import { Button, Table, Row, Spin, Typography } from "antd4";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

import { BASE_URL } from "../../../Helpers/const";
import NewAuditorModal from "./NewAuditorModal";
import AuditorSchedules from "./AuditorSchedules";

const COLUMNS = [
  {
    title: <Typography.Text strong>Auditors</Typography.Text>,
    dataIndex: "full_name",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.full_name.localeCompare(b.full_name),
    render: (_, record) => (
      <span style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Text strong>{record.full_name}</Typography.Text>
        <Typography.Text type="secondary">{record.email}</Typography.Text>
      </span>
    ),
  },
];

const AuditorsTab = ({ venues }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [auditors, setAuditors] = React.useState([]);

  React.useEffect(() => {
    fetch(BASE_URL + "/auditors", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then(({ auditors }) => setAuditors(auditors))
      .finally(() => setIsLoading(false));
  }, []);

  const addNewAuditor = (newAuditor) => setAuditors([newAuditor, ...auditors]);

  const addNewAuditSchedule = (auditorId, newAuditSchedule) =>
    setAuditors(
      auditors.map((auditor) =>
        auditor.id === auditorId
          ? {
              ...auditor,
              audit_schedules: [...auditor.audit_schedules, newAuditSchedule],
            }
          : auditor
      )
    );

  const expandableConfig = {
    expandIcon: ({ expanded }) =>
      expanded ? <DownOutlined /> : <RightOutlined />,
    expandRowByClick: true,
    expandedRowRender: (auditor) => (
      <AuditorSchedules
        auditorId={auditor.id}
        schedules={auditor.audit_schedules}
        venues={venues}
        addNewAuditSchedule={addNewAuditSchedule}
      />
    ),
  };

  if (isLoading) return <Spin />;
  return (
    <>
      <Row
        style={{ textAlign: "right", maxWidth: 850, margin: "auto" }}
        onClick={() => setIsModalVisible(true)}
      >
        <Button type="primary">Add an auditor</Button>
      </Row>
      <Table
        rowKey="id"
        columns={COLUMNS}
        dataSource={auditors}
        expandable={expandableConfig}
        style={{ width: 800, margin: "auto" }}
      />
      <NewAuditorModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        addNewAuditor={addNewAuditor}
      />
    </>
  );
};

export default AuditorsTab;
