import * as React from "react";
import Icon from "@ant-design/icons";
import image from "./red-down-right-arrow.png";

export const DownArrowIcon = ({ pixelSize }) => {
  return (
    <Icon
      component={() => (
        <img
          alt="Down right arrow"
          width={pixelSize}
          height={pixelSize}
          src={image}
        />
      )}
    />
  );
};
