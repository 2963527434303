import React from "react";
import { Checkbox, Form, Input, InputNumber, Modal, Select } from "antd4";

import { BASE_URL } from "../../../Helpers/const";

const NewAuditScheduleModal = ({
  auditorId,
  venues,
  isModalVisible,
  setIsModalVisible,
  addNewAuditSchedule,
}) => {
  const [venueId, setVenueId] = React.useState(venues[0].id);
  const [monday, setMonday] = React.useState(false);
  const [tuesday, setTuesday] = React.useState(false);
  const [wednesday, setWednesday] = React.useState(false);
  const [thursday, setThursday] = React.useState(false);
  const [friday, setFriday] = React.useState(false);
  const [saturday, setSaturday] = React.useState(false);
  const [sunday, setSunday] = React.useState(false);
  const [timeOfDay, setTimeOfDay] = React.useState(null);
  const [frequencyInWeeks, setFrequencyInWeeks] = React.useState(1);

  const handleSubmit = () => {
    fetch(BASE_URL + "/audit_schedules", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({
        audit_schedule: {
          auditor_id: auditorId,
          venue_id: venueId,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
          time_of_day: timeOfDay,
          frequency_in_weeks: frequencyInWeeks,
        }
      }),
    })
      .then((res) => res.json())
      .then(({ audit_schedule: newAuditSchedule }) => {
        addNewAuditSchedule(auditorId, newAuditSchedule);
        setIsModalVisible(false);
      })
      .catch(({ error }) => console.error(error));
  };

  const alphabetisedVenues = (venues) => {
    return venues.sort(function(a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleSubmit}
      onCancel={() => setIsModalVisible(false)}
    >
      <Form>
        <Form.Item label="Venue">
          <Select
            value={venueId}
            onChange={(newVenueId) => setVenueId(newVenueId)}
          >
            {alphabetisedVenues(venues).map(({ id, name }) => (
              <Select.Option value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Weekdays">
          <Checkbox checked={monday} onChange={() => setMonday(!monday)}>
            Monday
          </Checkbox>
          <br />
          <Checkbox checked={tuesday} onChange={() => setTuesday(!tuesday)}>
            Tuesday
          </Checkbox>
          <br />
          <Checkbox
            checked={wednesday}
            onChange={() => setWednesday(!wednesday)}
          >
            Wednesday
          </Checkbox>
          <br />
          <Checkbox checked={thursday} onChange={() => setThursday(!thursday)}>
            Thursday
          </Checkbox>
          <br />
          <Checkbox checked={friday} onChange={() => setFriday(!friday)}>
            Friday
          </Checkbox>
          <br />
          <Checkbox checked={saturday} onChange={() => setSaturday(!saturday)}>
            Saturday
          </Checkbox>
          <br />
          <Checkbox checked={sunday} onChange={() => setSunday(!sunday)}>
            Sunday
          </Checkbox>
          <br />
        </Form.Item>
        <Form.Item label="Time of day">
          <Input
            placeholder="e.g. 17:00"
            value={timeOfDay}
            onChange={(e) => setTimeOfDay(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Frequency (weeks)">
          <InputNumber
            value={frequencyInWeeks}
            onChange={(newFrequencyInWeeks) =>
              setFrequencyInWeeks(newFrequencyInWeeks)
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewAuditScheduleModal;
