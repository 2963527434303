import React from 'react'
import { Row, Form, Icon, Input, Button } from 'antd'
import { formRules } from '../../Helpers/const'
import SuccessMsg from './SuccessMsg'

const ForgotPw = ({ submit, switchView, form, load, success }) => {
  const { getFieldDecorator } = form

  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) submit(values, 'forgot')
    })
  }

  const inLineLink = (newView, label) => (
    <Button type='link' style={{ padding: 0 }} onClick={() => switchView(newView)}>
      {label}
    </Button>
  )

  if (success) {
    return (
      <SuccessMsg
        title={'Recovery email sent.'}
        subtitle={'Check your email for a link to setup your new password.'}
      />
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator('email', { rules: [formRules.email] })(
          <Input
            prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
            type='email'
            placeholder='Email'
          />
        )}
      </Form.Item>
      <Button type='primary' htmlType='submit' size='large' loading={load} block>
        Send Recovery Email
      </Button>
      <Row style={{ width: '100%', textAlign: 'center', marginTop: 20, marginBottom: 24 }}>
        No account? {inLineLink('signup', 'Sign up now.')}
      </Row>
    </Form>
  )
}

const ForgotPwForm = Form.create({ name: 'forgot_pw' })(ForgotPw)
export default ForgotPwForm
