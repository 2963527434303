import React from "react";
import Phrase from "./Phrase"

const BrandPhrases = ({phrases}) => {

  const listPhrases = () => {
    return phrases.map(phrase => <Phrase sentence={phrase} />)
  }

  return (
    <>
      { listPhrases() }
    </>
  )
}
export default BrandPhrases;