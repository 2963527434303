import React from 'react'
import { Row, Form, Icon, Input, Button, Checkbox } from 'antd'
import { formRules } from '../../Helpers/const'

const Login = ({ submit, switchView, form, load }) => {
  const { getFieldDecorator } = form

  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) submit(values, 'login')
    })
  }

  const inLineLink = (newView, label) => (
    <Button type='link' style={{ padding: 0 }} onClick={() => switchView(newView)}>
      {label}
    </Button>
  )

  const fieldIcon = (type) => <Icon type={type} style={{ color: 'rgba(0,0,0,.25)' }} />

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator('email', { rules: [formRules.email] })(
          <Input prefix={fieldIcon('user')} type='email' placeholder='Email' />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', { rules: [formRules.loginPw] })(
          <Input.Password prefix={fieldIcon('lock')} placeholder='Password' />
        )}
      </Form.Item>
      <Form.Item>
        <Row type='flex' justify='space-between'>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)}
          {inLineLink('forgot', 'Forgot password')}
        </Row>
        <Button type='primary' htmlType='submit' size='large' loading={load} block>
          Log in
        </Button>
        <Row style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
          No account? {inLineLink('signup', 'Sign up now.')}
        </Row>
      </Form.Item>
    </Form>
  )
}

const LoginForm = Form.create({ name: 'login' })(Login)
export default LoginForm
