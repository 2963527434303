import { Transfer, Row, Col, Button } from 'antd'
import React, { useState } from 'react'
import { useAuthValue } from '../../Auth/AuthContext'
import { USERS_URL, carrot, lightBlue } from '../../Helpers/const'
import { call } from '../../Helpers/Call'

const Venues = ({ user, setExpanded, venueList }) => {
  const { token } = useAuthValue()
  const [selected, setSelected] = useState(user.venues.map(ven => ven.id))

  const getTableList = () => {
    const dataSource = venueList.map(venue => {
      const ids = user.venues.map(ven => ven.id)
      return {
        key: venue.id,
        title: venue.name,
        chosen: ids.includes(venue.id),
      }
    })
    return dataSource
  }

  const handleChange = targetKeys => {
    const url = `${USERS_URL}/${user.id}`
    const onSuccess = data => setSelected(data.venues.map(choice => choice.id))
    call(url, 'PUT', token, onSuccess, { venue_ids: targetKeys })
  }

  if (venueList.length) {
    return (
      <>
        <Row style={{ textAlign: 'right' }}>
          <Button icon='close' size='small' onClick={() => setExpanded([])} />
        </Row>
        <Row style={{ width: 570, fontWeight: 600, textAlign: 'center', margin: 'auto' }}>
          <Col span={7} style={{ color: lightBlue, textAlign: 'right' }}>
            Available
          </Col>
          <Col span={5} />
          <Col span={12} style={{ color: carrot }}>
            Owned by {user.name}
          </Col>
        </Row>
        <Row style={{ textAlign: 'center' }}>
          <Transfer
            dataSource={getTableList()}
            targetKeys={selected}
            render={item => item.title}
            operations={['Add', 'Remove']}
            locale={{
              itemUnit: 'venue',
              itemsUnit: 'venues',
            }}
            onChange={handleChange}
          />
        </Row>
      </>
    )
  } else return <div />
}

export default Venues
