import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import { useAuthValue } from '../../Auth/AuthContext'
import { Redirect, useLocation } from 'react-router-dom'
import { PAGES, REPORTS_URL } from '../../Helpers/const'
import Report from './Report'
import { call } from '../../Helpers/Call'
const { Content } = Layout

const ReportPage = () => {
  const { token } = useAuthValue()
  const [report, setReport] = useState()

  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()

  useEffect(() => {
    const getReport = async () => {
      if (query.get('uid') && query.get('report')) {
        const creatorId = Number(query.get('uid'))
        const reportId = Number(query.get('report'))

        const url = `${REPORTS_URL}/${creatorId}/${reportId}`
        const onSuccess = data => setReport(data)
        const onError = () => setReport(0)

        await call(url, 'GET', token, onSuccess, null, onError)
      }
    }
    if (!report) getReport()
  })

  if (report === 0) return <Redirect to={{ pathname: PAGES.REPORTS.route }} />
  else {
    return (
      <Content style={{ padding: 25 }}>
        <Report report={report} />
      </Content>
    )
  }
}

export default ReportPage
