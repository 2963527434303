import React, { useState, useEffect } from "react";
import { Tabs, Row, Button } from "antd";
import { Redirect } from "react-router-dom";
import { useAuthValue } from "../../Auth/AuthContext";
import { PAGES, VENUES_URL } from "../../Helpers/const";
import { call } from "../../Helpers/Call";
import UsersAdmin from "./UsersTab";
import VenueAdmin from "./VenuesTab";
import NewVenueForm from "./NewVenue";
import AuditorsTab from "./AuditorsTab/AuditorsTab";

const { TabPane } = Tabs;

const Admin = () => {
  const { token, user } = useAuthValue();
  const [venueList, setVenueList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const onSuccess = (data) => setVenueList(data);
    call(VENUES_URL, "GET", token, onSuccess);
  }, [user, token]);

  if (user.role === "admin") {
    return (
      <>
        <Tabs defaultActiveKey="3" type="card">
          <TabPane tab="Users" key="1">
            <UsersAdmin venueList={venueList} />
          </TabPane>
          <TabPane tab="Venues" key="2">
            <Row style={{ textAlign: "right", maxWidth: 850, margin: "auto" }}>
              <Button
                type="primary"
                style={{ margin: "15px 0px 15px 15px" }}
                onClick={() => setModalVisible(true)}
              >
                Add a new venue
              </Button>
              {modalVisible && (
                <NewVenueForm
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                />
              )}
            </Row>
            <VenueAdmin venueList={venueList} setVenueList={setVenueList} />
          </TabPane>
          <TabPane tab="Auditors" key="3">
            <AuditorsTab venues={venueList} />
          </TabPane>
        </Tabs>
      </>
    );
  } else return <Redirect to={{ pathname: PAGES.CREATE.ROUTE }} />;
};

export default Admin;
