import React from "react";

import "./ContainersRecountTable.css";

export default function ContainersRecountTable({
  containers,
  containerRecounts,
  handleChange,
}) {
  if (containers.length === 0)
    return (
      <p id="no-containers-notice">
        <i>(No bottles to recount)</i>
      </p>
    );

  const containerIds = containers.map((c) => c.id);
  const isStateLoaded = containerIds.every((id) => containerRecounts[id]);

  if (!isStateLoaded) return <></>;

  return (
    <table id="brands-count-table">
      <thead>
        <tr>
          <th className="icon-col">Action</th>
          <th className="label-col">Size</th>
          <th className="input-col">Count (units)</th>
          <th className="input-col">Weight (grams)</th>
        </tr>
      </thead>
      <tbody>
        {containers.map((container) => {
          const {
            id: containerId,
            size,
            open_bottles,
            actions,
            special_notes,
          } = container;
          return (
            <>
              <tr>
                <td className="icon-col">
                  <div className="icon-cell">
                    {actions.map((action) => (
                      <span>{action}</span>
                    ))}
                  </div>
                </td>
                <td className="label-col">
                  <label>
                    <b>{size}</b>
                  </label>
                </td>
                <td className="input-col">
                  <div className="input-col-wrapper">
                    <input
                      type="number"
                      // placeholder={full_original_count}
                      value={containerRecounts[containerId].full_recount}
                      onChange={(e) => handleChange(e, containerId, "full")}
                    />
                  </div>
                </td>
                <td className="input-col">
                  {open_bottles.map(({ id: bottleId, weight }) => {
                    return (
                      <input
                        type="number"
                        // placeholder={weight}
                        value={
                          containerRecounts[containerId].open_bottles[bottleId]
                        }
                        onChange={(e) =>
                          handleChange(e, containerId, "open", bottleId)
                        }
                      />
                    );
                  })}
                </td>
              </tr>
              {special_notes.lenght > 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="brand-actions">
                      {special_notes.map((note) => {
                        return <p>{note}</p>;
                      })}
                    </div>
                  </td>
                </tr>
              ) : null}
            </>
          );
        })}
      </tbody>
    </table>
  );
}

export const renderUnicode = (hexNumber) =>
  String.fromCodePoint(parseInt(hexNumber, 16));
