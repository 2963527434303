import React, { useState } from "react";
import { Typography, Divider } from "antd";
import ClassInsights from "../../Components/ClassInsights";
import EmailForm from "../../Components/EmailForm";
import { UpArrowIcon } from "../../Assets/UpArrowIcon";
import { DownArrowIcon } from "../../Assets/DownArrowIcon";
const { Title } = Typography;

const Report = ({ report }) => {
  const [displayEmailForm, setDisplayEmailForm] = useState(false);

  // report_content = {
  //   whole_venue: {
  //     current_period_percent_shortage: 0.0,
  //     previous_period_percent_shortage: 0.0,
  //   },
  //   alcohol_types: {
  //       "<Class A>": {
  //         current_period_percent_shortage: 0.0,
  //         previous_period_percent_shortage: 0.0,
  //         class_phrases: ["", ""],
  //         brands: {
  //           "<Brand A>": ["", ""],
  //           "<Brand B>": ["", ""],
  //           ...
  //         }
  //       },
  //       "<Class B>": { ... },
  //       ...
  //     }
  //   },
  // }
  const displayAllInsights = () => {
    const parsedContent = JSON.parse(report.content);
    return Object.keys(parsedContent.alcohol_types).map((alcohol_class) => {
      return (
        <ClassInsights
          key={alcohol_class}
          name={alcohol_class}
          class_variances_and_phrases={
            parsedContent.alcohol_types[alcohol_class]
          }
        />
      );
    });
  };

  if (report) {
    const { whole_venue } = JSON.parse(
      report.content.replace(/=>/g, ":").replace(/\\n/g, "\\\\n")
    );

    const venuePercentShortageDirectionIcon =
      whole_venue.current_period_percent_shortage >
      whole_venue.previous_period_percent_shortage ? (
        <UpArrowIcon pixelSize={60} />
      ) : (
        <DownArrowIcon pixelSize={60} />
      );

    const venuePercentShortageDifference =
      whole_venue.current_period_percent_shortage -
      whole_venue.previous_period_percent_shortage;

    return (
      <>
        <br />
        {displayEmailForm ? (
          <EmailForm
            reportId={report.id}
            setDisplayEmailForm={setDisplayEmailForm}
          />
        ) : (
          <button onClick={() => setDisplayEmailForm(true)}>
            Email Report
          </button>
        )}
        <br />
        <br />
        <Title level={1}>Beverage Report</Title>
        <Title level={2}>
          {report.venue.name}
          {venuePercentShortageDirectionIcon} [
          {venuePercentShortageDifference.toFixed(1)}%]
        </Title>
        <Divider />
        {displayAllInsights()}
      </>
    );
  } else return <div />;
};
export default Report;
