import React, { useState } from 'react'
import { Form, Input, Button, Modal, Typography, Checkbox } from 'antd'
import { formRules, VENUES_URL } from '../../Helpers/const'
import { useAuthValue } from '../../Auth/AuthContext'
import { call } from '../../Helpers/Call'
const { Item } = Form
const { Title, Text } = Typography

const itemMargin = { marginBottom: 10 }

const NewVenue = ({ form, modalVisible, setModalVisible }) => {
  const [loading, setLoading] = useState(false)
  const { token, setUser } = useAuthValue()
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    validateFields(async (err, values) => {
      if (!err) {
        const onSuccess = data => {
          setUser(data)
          localStorage.setItem('profile', JSON.stringify(data))
          setModalVisible(false)
        }
        await call(VENUES_URL, 'POST', token, onSuccess, values)
        setLoading(false)
      }
    })
  }

  return (
    <Modal
      title={
        <Title level={4} style={{ fontWeight: 500, color: 'black', marginBottom: 0 }}>
          Add a new venue
        </Title>
      }
      onCancel={() => setModalVisible(false)}
      visible={modalVisible}
      footer={null}>
      <Form onSubmit={handleSubmit} hideRequiredMark={true}>
        <Item label='Venue Name' style={itemMargin}>
          {getFieldDecorator('name', { rules: [formRules.name] })(<Input />)}
        </Item>
        <Item label='Client ID' style={itemMargin}>
          {getFieldDecorator('client_id', { rules: [formRules.clientId] })(<Input />)}
        </Item>
        <Item label='Access Code' style={itemMargin}>
          {getFieldDecorator('access_code', { rules: [formRules.accessCode] })(<Input />)}
        </Item>
        {getFieldDecorator('history', {
          valuePropName: 'checked',
          initialValue: false,
        })(
          <Checkbox style={{ ...itemMargin, marginTop: 10 }}>
            Include client's past data in reports (if available)
          </Checkbox>
        )}
        <Item>
          <Button
            type='primary'
            htmlType='submit'
            size='large'
            loading={loading}
            block
            style={{ marginTop: 20 }}>
            Create Venue
          </Button>
        </Item>
        {loading && <Text type='secondary'>This may take a couple of minutes...</Text>}
      </Form>
    </Modal>
  )
}

const NewVenueForm = Form.create({ name: 'newVenue' })(NewVenue)
export default NewVenueForm
