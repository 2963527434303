import React, { useState } from 'react'
import { formRules } from '../../Helpers/const'
import { Form, Input, Button } from 'antd'
const noMargin = { marginBottom: 0 }

const ChangePassword = ({ form, saveChange }) => {
  const [isMatch, setIsMatch] = useState(false)
  const { getFieldDecorator, validateFields, getFieldValue } = form
  const handleConfirmBlur = e => setIsMatch(isMatch || !!e.target.value)
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) saveChange(values)
    })
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback(formRules.confirmPw.message)
    } else callback()
  }

  const validateToNextPassword = (rule, value, callback) => {
    if (value && isMatch) {
      validateFields(['confirm'], { force: true })
    }
    callback()
  }

  return (
    <Form onSubmit={handleSubmit} hideRequiredMark={true}>
      <Form.Item label='New password' style={noMargin} hasFeedback>
        {getFieldDecorator('password', {
          rules: [formRules.changePw, { validator: validateToNextPassword }]
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label='Repeat new password' style={noMargin} hasFeedback>
        {getFieldDecorator('password_confirmation', {
          rules: [formRules.confirmPw, { validator: compareToFirstPassword }]
        })(<Input.Password onBlur={handleConfirmBlur} />)}
      </Form.Item>
      <Button type='primary' size='small' style={{ margin: '10px 0px' }} htmlType='submit'>
        Save
      </Button>
    </Form>
  )
}

const ChangePw = Form.create({ name: 'changePassword' })(ChangePassword)
export default ChangePw
