import React from "react";

import "./AlcoholBrandCard.css";
import BrandInformationBanner from "./BrandInformationBanner";
import ContainersRecountTable from "./ContainersRecountTable";

export default function AlcoholBrandCard({
  alcoholBrand,
  containerRecounts,
  handleChange,
  numOfBrands,
  currentBrandNum,
}) {
  const {
    name,
    class_name: className,
    category,
    containers,
    actions,
    special_notes,
  } = alcoholBrand;

  return (
    <>
      <BrandInformationBanner
        name={name}
        className={className}
        category={category}
        actions={actions}
        special_notes={special_notes}
        numOfBrands={numOfBrands}
        currentBrandNum={currentBrandNum}
      />
      <ContainersRecountTable
        containers={containers}
        containerRecounts={containerRecounts}
        handleChange={handleChange}
      />
    </>
  );
}
