import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Spin } from "antd";
import "./Styles/App.css";
import BrandWrap from "./Components/BrandWrap";
import {
  Account,
  Reports,
  Admin,
  CreateReportForm,
  AuthCard,
  ReportPage,
  ResetPassword,
  VenuePage,
  Approval,
} from "./Views";
import { useAuthValue } from "./Auth/AuthContext";
import { PAGES } from "./Helpers/const";
import AuditReport from "./Views/AuditReport/AuditReport";
import AuditSchedule from "./Views/AuditSchedule/AuditSchedule";

const App = () => {
  const { user, loggedIn, loading } = useAuthValue();
  const {
    REPORTS,
    CREATE,
    ACCOUNT,
    ADMIN,
    LOGIN,
    REPORT,
    RESET,
    VENUE,
    APPROVE,
  } = PAGES;

  const match = useRouteMatch("/audit/:auditReportId");

  if (loading) return <Spin />;

  if (match && match.isExact)
    return <AuditReport auditReportId={match.params.auditReportId} />;

  return (
    <BrandWrap>
      <Route path={LOGIN.route} render={(props) => <AuthCard {...props} />} />
      <Route exact path={RESET.route} component={ResetPassword} />
      <Route exact path={APPROVE.route} component={Approval} />
      {user && loggedIn && !loading && (
        <Switch>
          <Route
            exact
            path={"/audit-schedule/:scheduleId"}
            component={AuditSchedule}
          />
          <Route exact path={"/"} component={CreateReportForm} />
          <Route exact path={REPORT.route} component={ReportPage} />
          <Route exact path={VENUE.route} component={VenuePage} />
          <Route exact path={REPORTS.route} component={Reports} />
          <Route exact path={ACCOUNT.route} component={Account} />
          <Route path={ADMIN.route} component={Admin} />
          <Route path={CREATE.route} component={CreateReportForm} />
        </Switch>
      )}
    </BrandWrap>
  );
};

export default App;
